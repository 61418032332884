"use client"

import DynamicImage from "@/components/fusion/dynamic-image"
import { Button, buttonVariants } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { SF_Pro } from "@/lib/fonts"
import { cn } from "@/lib/utils"
import { motion } from "framer-motion"
import { useRouter } from "next/navigation"
import { useEffect } from "react"

// Error boundaries must be Client Components
export default function GlobalError({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])
  const router = useRouter()
  return (
    <html lang="en" className="h-full">
      <body>
        <div className="h-screen w-full flex justify-center items-center dark:bg-gray-950 ">
          <div className="relative w-full h-screen ">
            {/* Card positioned absolutely in the center */}
            <div className="absolute inset-0 flex justify-center items-center z-10">
              <Card className="w-fit p-10 rounded-xl max-w-lg flex flex-col gap-4 shadow">
                <div className="flex gap-4 ">
                  <DynamicImage
                    alt={"image"}
                    darkImage={"/images/heroimage-dark.svg"}
                    defaultImage={"/images/heroimage-light.svg"}
                    height={100}
                    width={100}
                  />
                </div>
                <div className="">
                  <h3 className="text-lg font-bold">Terjadi Kesalahan</h3>
                  <p className="text-xs text-slate-400">{error.message}</p>
                </div>
                <div className="flex gap-3 flex-col justify-center mt-5">
                  <button
                    onClick={() => window.location.reload()}
                    className={cn(
                      buttonVariants({ variant: "default" }),
                      "flex gap-3 items-center",
                    )}>
                    Coba Lagi
                    <motion.div
                      className={SF_Pro.className}
                      animate={{ rotate: -360 }}
                      transition={{
                        duration: 0.5,
                        ease: "linear",
                      }}>
                      􀅉
                    </motion.div>
                  </button>
                  <p className="text-xs text-center text-slate-400">
                    Mohon maaf atas ketidaknyamanannya
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </body>
    </html>
  )
}
